import { useState } from 'react';

import { AssetType } from 'floor-plan-editor-2d';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/* eslint-disable react-hooks/exhaustive-deps */
import { PunchListItem } from './PunchListItem';
import { PunchListEmptyState } from './PunchListEmptyState';
import { useAppDispatch, useAppSelector } from '@store/store';
import { isArrayWithValues } from '@shared/util/array-util';
import { If } from '@components/Utils/Structural';
import { MetadataMarkerDrawer } from '../../MatadataMarkerDrawer/MetadataMarkerDrawer';
import { IPunchListItem } from '@models/punch-list.model';
import { highlightPunchListItem, selectPunchListItem } from '@store/slices/punch-list';
import { ViewDetailsPunchListItemDrawer } from '../../MatadataMarkerDrawer/ViewDetailsPunchListItemDrawer';
import useVisible from '@HOOKs/UseVisible';
import { IAssetSR as IAsset } from '../../../FloorPlanEditor/interfaces/interfaces';
import { getUniqueIdWithUUID } from '@infrastructure/repositories/utils.repository';

export const PunchListContainer = () => {
  const dispatch = useAppDispatch();

  const { punchList, loading } = useAppSelector(store => store.PunchList);

  const [visiblMarkerDrawer, toggleVisiblMarkerDrawer] = useVisible();

  const [visiblePunchListDetailsDrawer, toggleVisiblPunchListDetailsDrawer] = useVisible();

  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null);

  const viewDetailsAndHighlightPunchListItem = (punchListItem: IPunchListItem) => {
    setSelectedAsset({
      // id: '',
      id: getUniqueIdWithUUID(),
      label: '',
      type: AssetType.MARKER,
      x: punchListItem.markerLocationX,
      y: punchListItem.markerLocationY,
      rotationAngle: 0,
      data: punchListItem,
    });

    dispatch(selectPunchListItem(punchListItem));

    toggleVisiblPunchListDetailsDrawer();
    dispatch(highlightPunchListItem(punchListItem));
  };

  const onEditSuccess = punchListItemModified => {
    dispatch(selectPunchListItem(punchListItemModified));

    setTimeout(() => {
      dispatch(highlightPunchListItem(punchListItemModified));
    }, 500);
  };

  return (
    <div className="pb-20">
      {/* Loading */}
      <If condition={loading}>
        <div className="flex flex-row items-center justify-center pt-30">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin={true} />} />
        </div>
      </If>

      {/* Punch List Empty State */}
      <If condition={!loading && !isArrayWithValues(punchList)}>
        <PunchListEmptyState />
      </If>

      {/* Punch List Item (List) */}
      <If condition={!loading && isArrayWithValues(punchList)}>
        <div className="overflow-auto pb-40 pt-5">
          {punchList.map(item => (
            <div className="pl-15 pr-15 pt-10 pb-10" key={item.id} onClick={() => viewDetailsAndHighlightPunchListItem(item)}>
              <PunchListItem punchListItem={item} />
            </div>
          ))}
        </div>
      </If>

      {/* Editting View (Drawer) */}
      <If condition={visiblMarkerDrawer}>
        <MetadataMarkerDrawer
          open={visiblMarkerDrawer}
          toogle={toggleVisiblMarkerDrawer}
          asset={selectedAsset!}
          onEditSuccess={onEditSuccess}
        />
      </If>

      {/* Details View (Drawer) */}
      <If condition={visiblePunchListDetailsDrawer}>
        <ViewDetailsPunchListItemDrawer
          open={visiblePunchListDetailsDrawer}
          toogle={toggleVisiblPunchListDetailsDrawer}
          asset={selectedAsset!}
        />
      </If>
    </div>
  );
};
