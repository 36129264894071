import { theme } from 'antd';
import { geekblue } from '@ant-design/colors';

import { CustomBox } from '@components/Utils/CustomBox';
import { PunchListItemNormalizedStatusEnum } from '@models/enumerations/punch-list-item-normalized-status.enum';

interface IPunchListItemStatus {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

type IPunchListItemStatusMap = {
  CLOSED: IPunchListItemStatus;
  IN_PROGRESS: IPunchListItemStatus;
  NEW: IPunchListItemStatus;
  OVERDUE: IPunchListItemStatus;
};

export const StatusPunchListItemTag = ({ status, label }: { status: PunchListItemNormalizedStatusEnum; label: string }) => {
  const {
    token: {
      colorErrorBorder,
      colorErrorTextActive,
      colorErrorBg,
      colorSuccessTextActive,
      colorSuccessBorder,
      colorSuccessBg,
      colorTextSecondary,
      colorFillSecondary,
      colorFillTertiary,
    },
  } = theme.useToken();

  const PUNCH_LIST_ITEM_STATUS_MAP: IPunchListItemStatusMap = {
    CLOSED: {
      backgroundColor: geekblue[0],
      borderColor: geekblue[2],
      textColor: geekblue[6],
    },
    IN_PROGRESS: {
      backgroundColor: colorFillTertiary,
      borderColor: colorFillSecondary,
      textColor: colorTextSecondary,
    },
    NEW: {
      backgroundColor: colorSuccessBg,
      borderColor: colorSuccessBorder,
      textColor: colorSuccessTextActive,
    },
    OVERDUE: {
      backgroundColor: colorErrorBg,
      borderColor: colorErrorBorder,
      textColor: colorErrorTextActive,
    },
  };

  return (
    <CustomBox
      style={{
        maxHeight: '1.75rem',
        backgroundColor: PUNCH_LIST_ITEM_STATUS_MAP[status].backgroundColor,
        border: `1px solid ${PUNCH_LIST_ITEM_STATUS_MAP[status].borderColor}`,
      }}
    >
      <span className="pt-4 pb-4 pl-8 pr-8 select-none" style={{ color: PUNCH_LIST_ITEM_STATUS_MAP[status].textColor }}>
        {label}
      </span>
    </CustomBox>
  );
};
