export const defaultLangKey = "en";

export const LANGUAGES = [
    { id: "en", description: "English" },
    { id: "es", description: "Español" },
]

export const INTERNATIONALIZATION_LANGUAGES = [
    {
      id: "en",
      translationsIncluded: true,
      languageLabelInEnglish: "English",
      language: "English",
      isActive: true,
    },
    {
      id: "es",
      translationsIncluded: false,
      languageLabelInEnglish: "Spanish",
      language: "Español",
    },
    {
      id: "zh",
      translationsIncluded: false,
      languageLabelInEnglish: "Chinese",
      language: "中文",
    },
    {
      id: "hi",
      translationsIncluded: false,
      languageLabelInEnglish: "Hindi",
      language: "Hindi",
    },
    {
      id: "fr",
      translationsIncluded: false,
      languageLabelInEnglish: "French",
      language: "Français",
    },
    {
      id: "ar",
      translationsIncluded: false,
      languageLabelInEnglish: "Arabic",
      language: "العربية",
    },
    {
      id: "fa",
      translationsIncluded: false,
      languageLabelInEnglish: "Persian",
      language: "Persian",
    }
    
]

export const CLIENT_IDIOMS = [
  {
    clientId: '__none__',
    translationsIncluded: true,
    clientDescription: 'Default - Use Language Selected',
  },
  {
    clientId: 'vuori',
    translationsIncluded: true,
    clientDescription: 'Vuori',
  },
  {
    clientId: 'gorjana',
    translationsIncluded: true,
    clientDescription: 'gorjana',
  },
  {
    clientId: 'solidcore',
    translationsIncluded: true,
    clientDescription: '[solidcore]',
  },
  {
    clientId: 'excelfitness',
    translationsIncluded: true,
    clientDescription: 'Excel Fitness',
  },
];