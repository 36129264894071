import { v4 as uuidv4 } from 'uuid';
import { UUID } from 'crypto';

import { generateUniqueStringWithTimestamp } from '@shared/util/date-utils';

export type StringORNumber = string | number;

export const getUniqueId = () => {
  return `${uuidv4()}-${generateUniqueStringWithTimestamp()}`;
};

export const getUniqueIdWithUUID = (): UUID => {
  return uuidv4() as UUID;
};
