import { getOneByProjectId } from '@store/slices/budget-extended';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Alert, theme, Typography } from 'antd'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const BudgetAlertLatestVersion = () => {

    const { entities: budgetRevisions } = useAppSelector(state => state.BudgetRevision);
    const { entity: budget } = useAppSelector(state => state.BudgetExtended);
    const { projectId } = useParams();

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const { Text } = Typography;
    const {
        token: { colorWarningTextActive },
    } = theme.useToken();

    const selectLatestBudget = () => {
        dispatch(getOneByProjectId({ projectId: projectId!, budgetId: budgetRevisions[0].id! }));
    }

    return (
      <>
        {/* Here I check first that budgetRevisions[0] is not undefined so the first time it renders it doesn't show the alert */}
        {budgetRevisions[0] && budgetRevisions[0]?.id !== budget?.id && (
          <div className="mt-15 mr-15 ml-15">
            <Alert
              type="warning"
              showIcon
              message={
                <div>
                  <Text style={{ color: `${colorWarningTextActive}` }}>{t('budget.warning.latestVersion')}</Text>
                  <Text
                    onClick={selectLatestBudget}
                    className="ml-5"
                    underline
                    style={{ color: `${colorWarningTextActive}`, cursor: 'pointer' }}
                  >
                    {t('budget.warning.clickHere')}
                  </Text>
                </div>
              }
            />
          </div>
        )}
      </>
    );
}

export default BudgetAlertLatestVersion;