import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Button, Divider, Space } from 'antd';

/* eslint-disable react-hooks/exhaustive-deps */
import { CustomDrawer } from '@components/Utils/CustomDrawer';
import { IAssetSR as IAsset } from '../../FloorPlanEditor/interfaces/interfaces';
import { CategoryBadge, THEME_BADGE_ENUM } from '../CategoryBadge';
import { formatFullNameMonth, fromNow, generateUniqueStringWithTimestamp } from '@shared/util/date-utils';
import { PunchListItemRichTextReadOnly } from '../SidePanel/PunchListContainer/ViewerRichText/DescriptionPunchListItemRichText';
import { AttachmentListOfPunchListItemContainer } from './AttachmentListOfPunchListItemContainer';
import { UsePunchListRouteSegment } from '../UsePunchListRouteSegment';
import { CommentListOfPunchListItemContainer } from './CommentListOfPunchListItemContainer';
import { MetadataMarkerDrawer } from './MetadataMarkerDrawer';
import { PunchListItemMenu } from './PunchListItemMenu';
import { If } from '@components/Utils/Structural';
import { useAppDispatch, useAppSelector } from '@store/store';
import { reset } from '@store/slices/attachment';
import { highlightPunchListItem, selectPunchListItem } from '@store/slices/punch-list';
import useVisible from '@HOOKs/UseVisible';

interface IViewDetailsPunchListItemDrawerProps {
  open: boolean;
  toogle: () => void;
  asset: IAsset;
}

export const ViewDetailsPunchListItemDrawer = (props: IViewDetailsPunchListItemDrawerProps) => {
  const { open, toogle, asset } = props;

  const { selectPunchListItem: punchListItem } = useAppSelector(store => store.PunchList);

  const [visiblEditDrawer, toogleEditDrawer] = useVisible();

  const navigate = useNavigate();

  const basePunchListUrlSegment = UsePunchListRouteSegment();

  const dispatch = useAppDispatch();

  const onViewDetails = () => {
    const punchListPageId = punchListItem?.punchlistPage?.id || '';
    const punchListItemId = punchListItem?.id || '';
    navigate(`${basePunchListUrlSegment}punch-list/page/${punchListPageId}/punch-list-item/${punchListItemId}`);
  };

  const onCloseDrawer = () => {
    toogle();
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const titleDrawer = (
    <div className="flex flex-row items-center">
      <span className="text-color-neutral-8 font-medium mr-16">{`Item #${punchListItem?.punchlistNumber}`}</span>
    </div>
  );

  const onEditSuccess = punchListItemModified => {
    dispatch(selectPunchListItem(punchListItemModified));

    setTimeout(() => {
      dispatch(highlightPunchListItem(punchListItemModified));
    }, 500);
  };

  return (
    <CustomDrawer
      title={titleDrawer}
      width={480}
      onClose={onCloseDrawer}
      open={open}
      closable={true}
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Space>
          <Button type="primary" onClick={onViewDetails}>
            View Details
          </Button>
        </Space>
      }
    >
      <div className="flex flex-row justify-between w-full items-center pl-8 pt-8 pb-8 pr-16 ">
        <div className="flex flex-row items-center">
          <span className="font-medium text-color-neutral-8 font-14-line-h-22 pr-8">Category:</span>
          <If condition={!!punchListItem?.punchlistCategory?.description}>
            <span className="pl-20">
              <CategoryBadge category={`${punchListItem?.punchlistCategory?.description}`} themeUI={THEME_BADGE_ENUM.THEME_MEDIUM} />
            </span>
          </If>
        </div>
      </div>

      <div className="flex flex-row justify-between w-full items-center pl-8 pt-8 pb-8 pr-16 ">
        <div className="flex flex-row items-center">
          <span className="font-medium text-color-neutral-8 font-14-line-h-22">Assigned to:</span>
          <Avatar className="cursor-default bg-color-neutral-4 ml-10" gap={4}>
            <span className="text-color-neutral-8">
              {`${punchListItem?.assignedTo?.firstName?.[0]}${punchListItem?.assignedTo?.lastName?.[0]}`?.toUpperCase?.()}
            </span>
          </Avatar>
          <span className="font-bold text-color-neutral-8 ml-10">{`${punchListItem?.assignedTo?.fullName || ''}`}</span>
        </div>

        <PunchListItemMenu openEdition={toogleEditDrawer} />
      </div>

      <div className="flex flex-row w-full pl-8 mt-8 pt-8 pb-8 pr-16 ">
        <span className="font-medium text-color-neutral-8 font-14-line-h-22">Required By:</span>
        <div className="flex flex-col ml-10">
          <span className="font-normal text-color-neutral-8 font-14-line-h-22">
            {formatFullNameMonth(punchListItem?.targetCompletionDate)}
          </span>
          <span className="font-normal text-color-neutral-6 mt-5 font-14-line-h-20">
            {fromNow(`${punchListItem?.targetCompletionDate}`)}
          </span>
        </div>
      </div>

      <Divider className="mt-15 mb-0" />

      <div className="flex flex-col pl-8 pr-8 mt-16">
        <span className="text-color-original">Description</span>
        <div className="mt-15 font-14-line-h-22 text-color-tertiary" key={generateUniqueStringWithTimestamp()}>
          {punchListItem?.description && <PunchListItemRichTextReadOnly payload={punchListItem?.description} />}
        </div>
      </div>

      <Divider className="mt-15 mb-0" />

      <div className="pl-8 mt-10 mb-10">
        <If condition={Boolean(punchListItem)}>
          <AttachmentListOfPunchListItemContainer punchListItem={punchListItem!} />
        </If>
      </div>

      <Divider className="mt-15 mb-0" />

      <div className="pl-8 mt-15 mb-10">
        <If condition={Boolean(punchListItem)}>
          <CommentListOfPunchListItemContainer punchListItem={punchListItem!} />
        </If>
      </div>

      <If condition={visiblEditDrawer && !!asset}>
        <MetadataMarkerDrawer
          open={visiblEditDrawer}
          toogle={toogleEditDrawer}
          destroyAssets={() => {}}
          asset={asset!}
          onEditSuccess={onEditSuccess}
        />
      </If>
    </CustomDrawer>
  );
};
