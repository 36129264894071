/* eslint-disable react-hooks/exhaustive-deps */
import { FloorPlanViewer } from "./FloorPlanViewer/FloorPlanViewer";
import { SidePanel } from "./SidePanel/SidePanel";
import { Col, Row } from "antd";
import { useAppDispatch } from "@store/store";
import { useEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useParams } from "react-router-dom";
import { getPunchListCategories, getPuchListItems, getPunchListPage, reset } from "@store/slices/punch-list";
import { isArrayWithValues } from '@shared/util/array-util';
import { isNumber } from '@shared/util/number-util';
import { getUsers } from "@store/slices/users-extended";
import useEventListener from "@HOOKs/UseEventListener";
import { If } from "@components/Utils/Structural";
import { useLayoutContex } from '@providers/LayoutProvider';

export const PunchListTab = () => {
  const dispatch = useAppDispatch();

  const [isInitialRequest, setIsInitialRequest] = useState(true);

  const { projectId } = useParams<'projectId'>();
  const { pageId } = useParams<'pageId'>();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (isInitialRequest) {
      setIsInitialRequest(false);
      if (projectId) {
        dispatch(getPunchListPage({ projectId }))
          .unwrap()
          .then(pages => {
            if (isArrayWithValues(pages)) {
              const selected = pageId ? pageId : pages[0].id;
              isNumber(selected) && dispatch(getPuchListItems({ pageId: selected! }));
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPunchListCategories());
  }, []);

  const { height: sidePanelHeight = 0, ref: sidePanelRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });

  const reference = useRef<HTMLDivElement>(null);

  const [maxHeightContainer, setMaxHeightContainer] = useState(0);

  useEffect(() => {
    handleSize();
  }, [sidePanelHeight]);

  const handleSize = () => {
    const topPosition = (reference?.current && reference?.current?.getBoundingClientRect?.().top) || 0;
    if (isNumber(topPosition)) {
      setMaxHeightContainer(window.innerHeight - topPosition);
    }
  };

  useEventListener('resize', handleSize);

  const { collapsePunchList } = useLayoutContex();

  return (
    <Row className="h-full" ref={reference}>
      <Col
        ref={sidePanelRef}
        span={`${collapsePunchList ? 1 : 6}`}
        className="overflow-hidden"
        style={{ maxHeight: maxHeightContainer, height: maxHeightContainer }}
      >
        <SidePanel />
      </Col>
      <Col span={`${collapsePunchList ? 23 : 18}`} className="overflow-hidden">
        <If condition={maxHeightContainer > 0}>
          <FloorPlanViewer height={maxHeightContainer} />
        </If>
      </Col>
    </Row>
  );
};
