
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Card, Col, Divider, Row, Timeline, Typography, theme } from 'antd';
import { CheckOutlined, FieldTimeOutlined, StopOutlined } from '@ant-design/icons';

import { BudgetApprovalPolicy, BudgetApprovalStatus, IBudgetApprover } from '@models/budget.model';
import { NEUTRAL_6_COLOR } from '@providers/ThemeProvider';
import { getBudgetApprovers } from '@store/slices/budget-approvers';
import { useAppDispatch, useAppSelector } from '@store/store';

const { Text } = Typography;

const BudgetApprovers = () => {
  const {
    token: {
      colorTextTertiary,
      colorFillSecondary,
      colorFillTertiary,
      colorTextQuaternary,
      colorTextSecondary,
      colorSuccessText,
      colorWarningActive,
      colorErrorText,
      colorSuccess,
    },
  } = theme.useToken();
  const { entity: budget } = useAppSelector(state => state.BudgetExtended);
  const { entity: budgetApprovers } = useAppSelector(state => state.BudgetApprover);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!budget.id) return;
    dispatch(getBudgetApprovers(budget.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  const fullNameStatusColors = {
    [BudgetApprovalStatus.PENDING_APPROVAL]: colorWarningActive,
    [BudgetApprovalStatus.APPROVED]: colorSuccessText,
    [BudgetApprovalStatus.REJECTED]: colorErrorText,
    [BudgetApprovalStatus.QUEUED]: colorTextSecondary,
  };

  const dotStatusColors = {
    [BudgetApprovalStatus.PENDING_APPROVAL]: colorWarningActive,
    [BudgetApprovalStatus.APPROVED]: colorSuccess,
    [BudgetApprovalStatus.REJECTED]: colorErrorText,
    [BudgetApprovalStatus.QUEUED]: NEUTRAL_6_COLOR,
  };

  const filteredApprovers = budgetApprovers.filter(item => item.budgetApprovalPolicy !== BudgetApprovalPolicy.INVALID);

  const ItemRenderTimelineApproval: React.FC<{ budgetApprover: IBudgetApprover; order: number }> = ({ budgetApprover, order }) => {
    const user = budgetApprover?.user || {};
    const { fullName = '' } = user;
    const role = budgetApprover?.role || {};

    return (
      <>
        <Row justify={'center'} align="middle" style={{ paddingBottom: order === filteredApprovers.length ? '0px' : '' }}>
          <Text className="mr-12" style={{ fontSize: '16px', fontWeight: 400, color: colorTextQuaternary }}>
            {order}.
          </Text>
          <Col>
            <Avatar className="cursor-pointer mr-5" gap={4}>
              {`${fullName.slice(0, 2)}`.toUpperCase()}
            </Avatar>
          </Col>
          <Col flex="auto">
            <div className="flex flex-col justify-center">
              <div className="flex flex-row justify-start w-full">
                <Text
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: budgetApprover?.status ? fullNameStatusColors[budgetApprover?.status] : colorTextSecondary,
                  }}
                >
                  {fullName}
                </Text>
              </div>
              <Text style={{ fontSize: '12px', fontWeight: 400, color: colorTextTertiary }}>{role.name}</Text>
            </div>
          </Col>
          <Col className="mr-16">
            {budgetApprover.status === BudgetApprovalStatus.APPROVED && (
              <CheckOutlined
                style={{
                  color: budgetApprover?.status ? fullNameStatusColors[budgetApprover?.status!] : colorSuccess,
                  fontSize: '20px',
                  opacity: '80%',
                }}
              />
            )}
            {budgetApprover.status === BudgetApprovalStatus.PENDING_APPROVAL && (
              <FieldTimeOutlined
                style={{
                  color: budgetApprover?.status ? fullNameStatusColors[budgetApprover?.status!] : colorWarningActive,
                  fontSize: '20px',
                  opacity: '80%',
                }}
              />
            )}
            {budgetApprover.status === BudgetApprovalStatus.REJECTED && (
              <StopOutlined
                style={{
                  color: budgetApprover?.status ? fullNameStatusColors[budgetApprover?.status!] : colorSuccess,
                  fontSize: '20px',
                  opacity: '80%',
                }}
              />
            )}
          </Col>
        </Row>
        {order !== filteredApprovers.length && (
          <Divider style={{ position: 'absolute', left: '-2rem', width: '100%', marginTop: '0.5rem', opacity: '20%' }} />
        )}
      </>
    );
  };

  const Dot: React.FC<{ budgetApprover: any }> = ({ budgetApprover }) => {
    return (
      <div
        style={{
          width: '0.6rem',
          height: '0.6rem',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          border: `2px solid ${budgetApprover.status ? dotStatusColors[budgetApprover.status] : NEUTRAL_6_COLOR}`,
          marginTop: '0.5rem',
        }}
      ></div>
    );
  };

  return (
    <Card
      className="m-15 "
      title={t('budget.approvers')}
      style={{ borderRadius: '0.25rem' }}
      styles={{
        header: {
          paddingLeft: 15,
          paddingRight: 15,
          fontSize: '16px',
          fontWeight: 500,
          color: colorTextTertiary,
        },
        body: { padding: 15 },
      }}
    >
      <div
        style={{
          border: `1px solid ${colorFillSecondary}`,
          background: `${colorFillTertiary}`,
          borderRadius: '0.25rem',
        }}
      >
        <Timeline
          className="bg-transparent mt-30 ml-20 budget-approvers-timeline"
          items={[
            ...filteredApprovers.map((item, index) => ({
              children: item.budgetApprovalPolicy !== BudgetApprovalPolicy.INVALID && (
                <ItemRenderTimelineApproval budgetApprover={item} order={index + 1} />
              ),
              dot: item.budgetApprovalPolicy !== BudgetApprovalPolicy.INVALID && <Dot budgetApprover={item} />,
            })),
          ]}
        />
      </div>
    </Card>
  );
};


export default BudgetApprovers;
