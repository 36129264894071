import {
  AssetType,
  IAsset,
  IDimensionsConfig,
  IFloorplanCallbacks,
  IFloorplanConfig,
  IdAndColor,
  MeasurementUnits,
} from "../interfaces/interfaces";
import * as d3 from "d3";
import { v4 } from "uuid";

import "../index.css";
import { Controller } from "./controller";
import { UUID } from "crypto";
import { Utils } from "./utils";
export class FloorPlanEditor {
  private instanceId = "i" + v4();
  private notificationSection: any;
  private addButtonsSection: any;
  private assetTypeSection: any;
  private editButtonsContainer: any;
  private additionResetAndClearButtons: any;
  private rotateButtons: any;
  private controller: Controller;
  private internalContainer: any;
  private selectedButton: any = null;
  private zoomControl: any;
  private addAssetMode: boolean = false;
  private svg;

  constructor(
    private container: HTMLElement,
    initialAssets: IAsset[] = [],
    private isEditMode: boolean = false,
    floorplanCallbacks?: IFloorplanCallbacks,
    floorplanConfig?: IFloorplanConfig
  ) {
    // Complete missing values with default values
    if (!floorplanConfig) floorplanConfig = {};
    let {
      backgroundColor,
      backgroundImage,
      gridPatternConfig,
      dimensionsConfig,
      showFloorplanControls,
      allowedAssetTypes,
      markerRadiusMeters,
    } = floorplanConfig;
    if (!floorplanConfig.measurementUnits)
      floorplanConfig.measurementUnits = MeasurementUnits.METERS;
    if (!allowedAssetTypes)
      allowedAssetTypes = [AssetType.CHAIR, AssetType.DESK, AssetType.MARKER];

    if (!markerRadiusMeters) markerRadiusMeters = 0.15;

    const {
      showMinimap = true,
      showZoomSlider = true,
      showResetButton = true,
      showClearButton = true,
      showDeleteButton = true,
      showDuplicateButton = true,
      showRotateButtons = true,
      showMoveButtons = true,
    } = showFloorplanControls || {};

    if (!dimensionsConfig)
      dimensionsConfig = {
        pixelsPerMeter: 100,
        officeWidth: 5,
        officeHeight: 3,
      };

    // Select all child elements inside the container and remove them
    d3.select(this.container).selectAll("*").remove();

    //Uses the div that is sent in the constructor and attaches a div inside
    this.internalContainer = d3
      .select(this.container)
      .append("div")
      .attr("class", "floor-plan-editor-container");

    this.svg = this.internalContainer
      .append("svg")
      .attr("id", this.instanceId)
      .attr("class", "floor-plan-editor-board")
      .style("background-color", backgroundColor || "gray");

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === this.svg.node()) {
          this.controller.refreshMinimap();
        }
      }
    });
    resizeObserver.observe(this.svg.node());

    const mainG = this.svg.append("g");

    if (showMinimap) {
      this.internalContainer
        .append("svg")
        .attr("class", "minimap")
        .attr("id", "minimap-" + this.instanceId);
    }

    mainG
      .attr("id", "mainG-" + this.instanceId)
      .attr(
        "width",
        dimensionsConfig.officeWidth * dimensionsConfig.pixelsPerMeter
      )
      .attr(
        "height",
        dimensionsConfig.officeHeight * dimensionsConfig.pixelsPerMeter
      )
      .attr("stroke", "currentColor")
      //tabindex is used to enable the svg to have draggable and clickable objects inside of it
      .attr("tabindex", "0");
    // Having already created the mainG we can construct the controller so it can draw the initial assets
    this.zoomControl = this.internalContainer
      .append("div")
      .attr("id", "zoom-control")
      .style("position", "absolute")
      .style("bottom", "10px")
      .style("left", "10px")
      .style("display", "flex")
      .style("flex-direction", "column")
      .style("align-items", "center");

    if (showZoomSlider) {
      const zoomSlider = this.zoomControl
        .append("input")
        .attr("id", "zoom-slider-" + this.instanceId)
        .attr("type", "range")
        .attr("min", 10)
        .attr("max", 200)
        .attr("step", 1)
        .attr("value", 100)
        .style("cursor", "pointer");

      const zoomLabel = this.zoomControl
        .append("span")
        .text("100%")
        .style("color", "currentColor");
    }

    this.controller = new Controller(
      this.instanceId,
      initialAssets,
      isEditMode,
      this.setPlacingMode,
      this.checkButtonsVisibility,
      markerRadiusMeters,
      floorplanConfig || {},
      floorplanCallbacks || {}
    );

    let isMousePressed = false;
    let intervalId: any;

    const boardRect = mainG
      .insert("rect", backgroundImage ? ":nth-child(2)" : ":first-child")
      .attr("id", "grid-" + this.instanceId)
      .attr(
        "width",
        this.controller.getDimensionsConfig().officeWidth *
          this.controller.getDimensionsConfig().pixelsPerMeter
      )
      .attr(
        "height",
        this.controller.getDimensionsConfig().officeHeight *
          this.controller.getDimensionsConfig().pixelsPerMeter
      );

    if (gridPatternConfig) {
      const patternId = "layout-grid-" + this.instanceId;
      const gridPattern = mainG
        .append("pattern")
        .attr("id", patternId)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("width", this.controller.getGridPixelsSize())
        .attr("height", this.controller.getGridPixelsSize());

      gridPattern
        .append("line")
        .attr("id", "grid-vertical-" + this.instanceId)
        .attr("x1", this.controller.getGridPixelsSize())
        .attr("y1", 0)
        .attr("x2", this.controller.getGridPixelsSize())
        .attr("y2", this.controller.getGridPixelsSize())
        .attr("stroke", gridPatternConfig.color || "red");

      gridPattern
        .append("line")
        .attr("id", "grid-horizontal-" + this.instanceId)
        .attr("x1", 0)
        .attr("y1", this.controller.getGridPixelsSize())
        .attr("x2", this.controller.getGridPixelsSize())
        .attr("y2", this.controller.getGridPixelsSize())
        .attr("stroke", gridPatternConfig.color || "red");

      boardRect.attr("fill", `url(#${patternId})`);
    } else {
      boardRect.attr("fill", "transparent");
    }

    //Creates an empty div on the top left of the svg to be used when a message needs to be displayed
    this.notificationSection = this.internalContainer
      .append("div")
      .attr("id", "notification-section")
      .style("position", "absolute")
      .style("top", "10px")
      .style("left", "10px")
      .style("display", "flex");
    // This div has the reset and clear buttons inside to hide or display them at the same time if  isEditMode changes its value
    this.additionResetAndClearButtons = this.internalContainer
      .append("div")
      .style("display", this.controller.getIsEditMode() ? "flex" : "none");

    if (allowedAssetTypes.length > 0) {
      this.addButtonsSection = this.additionResetAndClearButtons
        .append("div")
        .attr("class", "add-buttons-container " + "add-asset-btn");
      // This div has the element addition buttons stored inside
      if (allowedAssetTypes.length > 1)
        this.addButtonsSection
          .append("button")
          .text("Add Asset")
          .attr("class", "floor-plan-editor-control-button " + "add-asset-btn")
          .style("top", "10px")
          .on("click", (event: MouseEvent) => {
            this.displayAddAssetOptions();
          });

      this.assetTypeSection = this.internalContainer
        .append("div")
        .style("display", allowedAssetTypes.length > 1 ? "none" : "block");

      //This counter is to set the spaces in between the created buttons
      let counter = 10;
      for (let i = 0; i < allowedAssetTypes.length; i++) {
        const selectAssetButton = (
          allowedAssetTypes.length > 1
            ? this.assetTypeSection
            : this.addButtonsSection
        )
          .append("button")
          .text(
            (allowedAssetTypes.length === 1 ? "Add " : "") +
              Utils.toTitleCase(allowedAssetTypes[i])
          )
          .attr(
            "class",
            "floor-plan-editor-" +
              (allowedAssetTypes.length > 1 ? "asset" : "control") +
              "-button " +
              Utils.toTitleCase(allowedAssetTypes[i]) +
              "-btn"
          )
          .style("top", counter + "px")
          .on("click", (event: MouseEvent) => {
            // Remove the "selected-button" class from the previously selected button
            if (this.selectedButton) {
              this.selectedButton.classed("selected-button", false);
            }
            // Add "selected-button" class to the clicked button and Set the currently selected button
            selectAssetButton.classed("selected-button", true);
            // Set the currently selected button
            this.selectedButton = selectAssetButton;
            //Sets the type to be added
            this.setPlacingMode(allowedAssetTypes![i]);
          });
        counter += 30;
      }
    }
    if (showResetButton) {
      const resetButton = this.additionResetAndClearButtons
        .append("button")
        .text("Reset")
        .attr("class", "floor-plan-editor-control-button reset-btn")
        .style("top", allowedAssetTypes.length > 0 ? "40px" : "10px")
        .on("click", (event: MouseEvent) => {
          this.reset();
        });
    }
    if (showClearButton) {
      const clearButton = this.additionResetAndClearButtons
        .append("button")
        .text("Clear")
        .attr("class", "floor-plan-editor-control-button clear-btn")
        .style("top", allowedAssetTypes.length > 0 ? "70px" : "40px")
        .on("click", (event: MouseEvent) => {
          this.clear();
        });
    }
    //Container that stores all buttons that are able to modify the asset's position or delete them
    this.editButtonsContainer = this.internalContainer
      .append("div")
      .style("position", "absolute")
      .style("min-width", "90px")
      .style("right", "0px")
      .style("top", "100px")
      .attr("class", "edit-btn")
      .style("display", this.controller.getIsEditMode() ? "flex" : "none");

    if (showDeleteButton) {
      const deleteButton = this.editButtonsContainer
        .append("button")
        .text("Delete")
        .attr("class", "floor-plan-editor-control-button delete-btn")
        .style("top", "40px")
        .on("click", (event: MouseEvent) => this.deleteSelectedAssetsAssets());
    }
    if (showDuplicateButton) {
      const duplicateButton = this.editButtonsContainer
        .append("button")
        .text("Duplicate")
        .attr("class", "floor-plan-editor-control-button duplicate-btn")
        .style("top", "70px")
        .on("click", (event: MouseEvent) => this.duplicate());
    }
    if (showRotateButtons) {
      this.rotateButtons = this.editButtonsContainer
        .append("div")
        .attr("class", "rotate-container")
        .style("position", "absolute")
        .style("min-width", "90px")
        .style("top", "100px")
        .style("right", "10px")
        .style("justify-content", "space-between")
        .style("display", "flex");

      const rotateLeftButton = this.rotateButtons
        .append("button")
        .text("↺")
        .attr("class", "rotate-left-btn")
        .style("min-width", "40px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.rotateLeft();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.rotateLeft();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });

      const rotateRightButton = this.rotateButtons
        .append("button")
        .text("↻")
        .attr("class", "rotate-right-btn")
        .style("min-width", "40px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.rotateRight();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.rotateRight();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });
    }
    if (showMoveButtons) {
      const moveUpButtonContainer = this.editButtonsContainer
        .append("div")
        .attr("class", "move-up-container")
        .style("position", "absolute")
        .style("min-width", "90px")
        .style("top", "130px")
        .style("right", "10px")
        .style("justify-content", "center")
        .style("display", "flex");

      const moveUpButton = moveUpButtonContainer
        .append("button")
        .text("▲")
        .attr("class", "move-up-btn")
        .style("min-width", "26px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.moveUp();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.moveUp();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });

      const moveButtonsContainer = this.editButtonsContainer
        .append("div")
        .attr("class", "move-btn-container")
        .style("position", "absolute")
        .style("width", "90px")
        .style("top", "155px")
        .style("right", "10px")
        .style("justify-content", "space-between")
        .style("display", "flex");

      const moveLeftButton = moveButtonsContainer
        .append("button")
        .text("◄")
        .attr("class", "move-left-btn")
        .style("min-width", "26px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.moveLeft();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.moveLeft();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });

      const moveDownButton = moveButtonsContainer
        .append("button")
        .text("▼")
        .attr("class", "move-down-btn")
        .style("min-width", "26px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.moveDown();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.moveDown();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });
      const moveRightButton = moveButtonsContainer
        .append("button")
        .text("►")
        .attr("class", "move-right-btn")
        .style("min-width", "26px")
        .style("cursor", "pointer")
        .style("color", "currentColor")
        .on("mousedown", (event: MouseEvent) => {
          isMousePressed = true;
          this.moveRight();
          intervalId = setInterval(() => {
            if (isMousePressed) {
              this.moveRight();
            }
          }, 200);
        })
        .on("mouseup", (event: MouseEvent) => {
          isMousePressed = false;
          clearInterval(intervalId);
        });
    }

    const handleArrowKey = (event: KeyboardEvent) => {
      event.preventDefault();
      if (
        this.controller.getSelectedAssets().length > 0 &&
        this.controller.getIsEditMode()
      ) {
        switch (event.key) {
          case "ArrowUp":
            this.moveUp();
            break;
          case "ArrowDown":
            this.moveDown();
            break;
          case "ArrowLeft":
            this.moveLeft();
            break;
          case "ArrowRight":
            this.moveRight();
            break;
        }
      }
    };
    // "Listens" to keydown
    mainG.on("keydown", handleArrowKey);
    this.checkButtonsVisibility();
  }

  measurementStart() {
    this.controller.measurementStart();
  }

  measurementStop() {
    this.controller.measurementStop();
  }

  measurementClear() {
    this.controller.measurementClear();
  }

  getAllAssets() {
    return this.controller.getAllAssets();
  }

  setAllAssets(assets: IAsset[]) {
    this.controller.setAllAssets(assets);
  }

  getBackgroundImage() {
    return this.controller.getBackgroundImage();
  }

  setBackgroundImage(url: string | undefined) {
    this.controller.setBackgroundImage(url);
  }

  getDimensionsConfig() {
    return this.controller.getDimensionsConfig();
  }

  setDimensionsConfig(dimensionsConfig: IDimensionsConfig) {
    this.controller.setDimensionsConfig(dimensionsConfig);
  }

  setBlinkingAssets(nodesToBlink: IdAndColor[]) {
    this.controller.blinkAssets(nodesToBlink);
  }

  isMultipleAddAllowed() {
    return this.controller.isMultipleAddAllowed();
  }

  setMultipleAddAllowed(allowMultipleAdd: boolean) {
    this.controller.setMultipleAddAllowed(allowMultipleAdd);
  }

  setBackgroundColor(color: string) {
    this.svg.style("background-color", color);
  }

  goTo(x: number, y: number, zoomLevel: number = 2) {
    this.controller.goTo(x, y, zoomLevel);
  }

  getCurrentZoomLevel() {
    const mainG = d3.select("#mainG-" + this.instanceId).node();

    const transform = d3.zoomTransform(mainG);

    // Access the scale factor
    const scale = transform.k;

    return scale;
  }

  startAddAssetMode(type: AssetType) {
    if (!this.isEditMode) return;
    this.addAssetMode = true;
    this.controller.setPlacingMode(type);
  }

  finishAddAssetMode() {
    this.addAssetMode = false;
    this.controller.setPlacingMode(null);
  }
  getAddAssetMode() {
    return this.addAssetMode;
  }

  duplicate() {
    if (!this.isEditMode) return;
    this.controller.handleDuplicate();
  }

  clear() {
    if (!this.isEditMode) return;
    this.controller.clear();
  }

  reset() {
    if (!this.isEditMode) return;
    this.controller.reset();
  }

  zoomToFit(duration = 1000) {
    this.controller.zoomToFit(duration);
  }

  setEditMode(isEditMode: boolean) {
    this.controller.setEditMode(isEditMode);
  }

  getEditMode() {
    return this.controller.getIsEditMode();
  }

  rotateLeft() {
    if (!this.isEditMode) return;
    this.controller.handleAssetsRotateRounded(-15);
  }

  rotateRight() {
    if (!this.isEditMode) return;
    this.controller.handleAssetsRotateRounded(15);
  }

  moveUp() {
    if (!this.isEditMode) return;
    if (this.controller.getSelectedAssets().length === 0) return;
    this.handleMovementButtonClick(
      0,
      -(this.controller.getGridPatternConfig()
        ? this.controller.getGridPixelsSize()
        : 10)
    );
  }
  moveDown() {
    if (!this.isEditMode) return;
    if (this.controller.getSelectedAssets().length === 0) return;
    this.handleMovementButtonClick(
      0,
      this.controller.getGridPatternConfig()
        ? this.controller.getGridPixelsSize()
        : 10
    );
  }
  moveLeft() {
    if (!this.isEditMode) return;
    if (this.controller.getSelectedAssets().length === 0) return;
    this.handleMovementButtonClick(
      -(this.controller.getGridPatternConfig()
        ? this.controller.getGridPixelsSize()
        : 10),
      0
    );
  }

  moveRight() {
    if (!this.isEditMode) return;
    if (this.controller.getSelectedAssets().length === 0) return;
    this.handleMovementButtonClick(
      this.controller.getGridPatternConfig()
        ? this.controller.getGridPixelsSize()
        : 10,
      0
    );
  }

  setSelectedAsset(assets: IAsset[], event?: MouseEvent) {
    this.controller.setSelectedAsset(assets, event);
  }

  updateAsset(updatedAsset: IAsset) {
    this.controller.updateAsset(updatedAsset);
  }

  deleteSelectedAssetsAssets() {
    if (!this.isEditMode) return;
    this.controller.handleDelete();
  }
  deleteAssets(assetsIds: UUID[]) {
    if (!this.isEditMode) return;
    this.controller.handleDeleteAssets(assetsIds);
  }

  destroy = () => {
    d3.select(this.container).selectAll("*").remove();
  };

  private handleMovementButtonClick = (dx: number, dy: number) => {
    this.controller.handleAssetsMoveRequest(dx, dy);
  };

  private toggleEditionButtonsVisibility = () => {
    if (this.editButtonsContainer)
      this.editButtonsContainer.style(
        "display",
        this.controller.getSelectedAssets().length > 0 &&
          this.controller.getIsEditMode()
          ? "block"
          : "none"
      );
  };

  private toggleAddRestClearButtonsVisibility = () => {
    if (this.additionResetAndClearButtons)
      this.additionResetAndClearButtons.style(
        "display",
        this.controller.getIsEditMode() ? "block" : "none"
      );
  };

  private toggleRotateButtonsVisibility = () => {
    if (this.rotateButtons)
      this.rotateButtons.style(
        "display",
        this.controller.getIsEditMode() &&
          this.controller.getSelectedAssets().length === 1
          ? "flex"
          : "none"
      );
  };

  private checkButtonsVisibility = () => {
    this.toggleAddRestClearButtonsVisibility();
    this.toggleEditionButtonsVisibility();
    this.toggleRotateButtonsVisibility();
  };

  private setPlacingMode = (type: AssetType | null) => {
    // I need to do this first to clear the previous message from add asset btn
    this.notificationSection.html("");
    //Adds message on the svg and button to stop add action
    if (!type) {
      this.notificationSection.html("");
      this.controller.setPlacingMode(null);
      this.selectedButton.classed("selected-button", false);
      this.addAssetMode = false;
      this.assetTypeSection.style("display", "none");
    } else {
      this.addAssetMode = true;
      this.controller.setPlacingMode(type);
      this.notificationSection
        .append("div")
        .text("Please place the " + type.toLowerCase())
        .style("margin-right", "10px");

      //check if multiple add feature is allowed and add either the done button or a one-time event listener
      this.notificationSection
        .append("button")
        .text(this.isMultipleAddAllowed() ? "Done" : "Cancel")
        .on("click", () => {
          this.notificationSection.html("");
          this.controller.setPlacingMode(null);
          this.addAssetMode = false;
          this.selectedButton.classed("selected-button", false);
          this.assetTypeSection.style("display", "none");
        });
    }
  };

  private displayAddAssetOptions() {
    // Get the current display value of the element
    var currentDisplay = this.assetTypeSection.style("display");
    if (currentDisplay === "none") {
      this.assetTypeSection.style("display", "block");
      this.notificationSection
        .append("div")
        .text("Please select the asset type")
        .style("margin-right", "10px");

      this.notificationSection
        .append("button")
        .text("Cancel")
        .on("click", (event: MouseEvent) => {
          this.notificationSection.html("");
          this.assetTypeSection.style("display", "none");
          this.controller.setPlacingMode(null);
          this.addAssetMode = false;
        });
    } else {
      this.notificationSection.html("");
      this.assetTypeSection.style("display", "none");
      this.selectedButton?.classed("selected-button", false);
      this.controller.setPlacingMode(null);
      this.addAssetMode = false;
    }
  }
}
