import { GlobalToken, theme } from "antd";
import React, { createContext, useContext, useLayoutEffect, useState } from "react";

export const LayoutContext = createContext(null as any);

export const NEW_STYLE = "NEW";
export const OLD_STYLE = "OLD";

export const LayoutProvider = ({ children }: any) => {

    const [lastStyleApplied, setLastStyleApplied] = useState<string>("");
    const [styleContent, setStyleContent] = useState<React.CSSProperties>({});
    const [headerSize, setHeaderSize] = useState<number | null>(null);
    const [headerLabelEntity, setHeaderLabelEntity] = useState<string>("");
    const [layoutSize, setLayoutSize] = useState<{ layoutWidth?: number; layoutHeight?: number }>({});
    const [collapseNav, setCollapseNav] = useState(false);
    const [collapsePunchList, setCollapsePunchList] = useState(false);

    const { token } = theme.useToken();
    const { colorBgContainer, colorBgLayout } = token;

    useLayoutEffect(() => {
        updateBrandColor(token);
        updateTextColor(token);
    }, [token])
    
    const spacing = 24;
    const oldStylePropertiesInContentLayout = {
        margin: `${spacing}px 16px`,
        padding: spacing,
        background: colorBgContainer
    }

    const newStylePropertiesInContentLayout = {
        margin: 0,
        padding: 0,
        background: colorBgLayout
    }

    useLayoutEffect(() => {
        if (lastStyleApplied !== "") {
            const styleToApply = lastStyleApplied === NEW_STYLE ? newStylePropertiesInContentLayout : oldStylePropertiesInContentLayout;
            setStyleContent(styleToApply);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorBgContainer, colorBgLayout])

    const value = {
      styleContent,
      headerSize,
      headerLabelEntity,
      layoutSize,
      collapseNav,
      collapsePunchList,
      setOldStylePropertiesInContentLayout: () => {
        setLastStyleApplied(OLD_STYLE);
        return setStyleContent(oldStylePropertiesInContentLayout);
      },
      setNewStylePropertiesInContentLayout: () => {
        setLastStyleApplied(NEW_STYLE);
        return setStyleContent(newStylePropertiesInContentLayout);
      },
      setStyleContentState(data: React.CSSProperties) {
        if (styleContent !== data) {
          setStyleContent(data);
        }
      },
      setLayoutSizeState(size: typeof layoutSize) {
        if (layoutSize !== size) {
          setLayoutSize(size);
        }
      },
      setHeaderSizeState(height: typeof headerSize) {
        if (headerSize !== height) {
          setHeaderSize(height);
        }
      },
      setHeaderLabelEntityState(label: string) {
        setHeaderLabelEntity(label);
      },
      setCollapseNavState(updateState: boolean) {
        if (collapseNav !== updateState) {
          setCollapseNav(updateState);
        }
      },
      setCollapsePunchList(updateState: boolean) {
        if (collapsePunchList !== updateState) {
          setCollapsePunchList(updateState);
        }
      },
    };

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutContex = () => {
    return useContext(LayoutContext);
};

export const updateBrandColor = (token: GlobalToken) => {
    document?.documentElement?.style?.setProperty?.(`--colorBgBase`, token.colorBgBase);
    document?.documentElement?.style?.setProperty?.(`--colorBgMask`, token.colorBgMask);
    document?.documentElement?.style?.setProperty?.(`--colorBgLayout`, token.colorBgLayout);
    document?.documentElement?.style?.setProperty?.(`--colorBgSpotlight`, token.colorBgSpotlight);
    document?.documentElement?.style?.setProperty?.(`--colorFill`, token.colorFill);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryBg`, token.colorPrimaryBg);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryBgHover`, token.colorPrimaryBgHover);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryBorder`, token.colorPrimaryBorder);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryBorderHover`, token.colorPrimaryBorderHover);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryHover`, token.colorPrimaryHover);
    document?.documentElement?.style?.setProperty?.(`--colorPrimary`, token.colorPrimary);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryActive`, token.colorPrimaryActive);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryTextHover`, token.colorPrimaryTextHover);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryText`, token.colorPrimaryText);
    document?.documentElement?.style?.setProperty?.(`--colorPrimaryTextActive`, token.colorPrimaryTextActive);
    document?.documentElement?.style?.setProperty?.(`--colorBorderSecondary`, token.colorBorderSecondary);
    document?.documentElement?.style?.setProperty?.(`--colorFillSecondary`, token.colorFillSecondary);
}
export const updateTextColor = (token: GlobalToken) => {
    document?.documentElement?.style?.setProperty?.(`--colorText`, token.colorText);
    document?.documentElement?.style?.setProperty?.(`--colorTextSecondary`, token.colorTextSecondary);
    document?.documentElement?.style?.setProperty?.(`--colorTextTertiary`, token.colorTextTertiary);
    document?.documentElement?.style?.setProperty?.(`--colorTextQuaternary`, token.colorTextQuaternary);
}

