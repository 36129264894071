import { useAppSelector } from '@store/store';
import { Card, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDateTargetOpening } from '@shared/util/date-utils';
import { getUniqueId } from '@infrastructure/repositories/utils.repository';

const { Text } = Typography;

const ProjectInfo = () => {
  const { data: projectOverview } = useAppSelector(state => state.ProjectOverview);
  const {
    token: { colorTextTertiary, colorFillSecondary, colorFillTertiary, colorTextSecondary },
  } = theme.useToken();
  const { t } = useTranslation();
  const { projectType, weeklyStatus, targetOpening } = projectOverview.headerData;
  const projectData = [
    { title: `${t('generic.project')} ${t('admin.type')}`, content: `${projectType.name}` },
    { title: `${t('generic.project')} ${t('location.status')}`, content: `${weeklyStatus.name}` },
    { title: `${t('location.openDate')}`, content: targetOpening ? formatDateTargetOpening(targetOpening) : '--' },
  ];

  return (
    <Card
      className="mt-15 mr-15 ml-15"
      title={`${t('generic.project')} ${t('generic.info')}`}
      style={{ borderRadius: '0.25rem' }}
      styles={{
        header: {
          paddingLeft: 15,
          paddingRight: 15,
          fontSize: '16px',
          fontWeight: 500,
          color: colorTextTertiary,
        },
        body: { paddingLeft: 15, paddingTop: 15, paddingBottom: 0, paddingRight: 0 },
      }}
    >
      <div className="grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)', grid: '15px' }}>
        {projectData.map(data => (
          <div
            key={getUniqueId()}
            className="flex mb-15 mr-15"
            style={{
              border: `1px solid ${colorFillSecondary}`,
              background: `${colorFillTertiary}`,
              borderRadius: '0.25rem',
              flexDirection: 'column',
            }}
          >
            <div className="m-10">
              <Text style={{ fontSize: '14px', fontWeight: 500, color: colorTextSecondary }}>{data.title}:</Text>
              <div>
                <Text style={{ fontSize: '14px', fontWeight: 400, color: colorTextTertiary }}>{data.content}</Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ProjectInfo;