import { theme } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { useLayoutContex } from '@providers/LayoutProvider';

interface ICollapsibleButtonProps {
  isActive?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const CollapsibleButton = (props: ICollapsibleButtonProps) => {
  const {
    token: { colorPrimary, colorPrimaryBg },
  } = theme.useToken();

  const { collapsePunchList, setCollapsePunchList } = useLayoutContex();

  const toogleNav = () => {
    setCollapsePunchList(!collapsePunchList);
  };

  return (
    <div
      onClick={toogleNav}
      className={`generic-transition cursor-pointer pt-5 pr-8 pb-5 pl-8 w-min ${
        collapsePunchList ? 'calculated-center' : 'calculated-left'
      }`}
      style={{ backgroundColor: colorPrimaryBg, borderRadius: '5px' }}
    >
      <RightOutlined
        className="generic-transition"
        style={{
          color: colorPrimary,
          transform: collapsePunchList ? 'rotateY(0deg)' : 'rotateY(180deg)',
        }}
      />
    </div>
  );
};
