/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useRef, useState } from 'react';

import { theme } from 'antd';
import { FloorPlanEditor, IFloorplanCallbacks, IFloorplanConfig } from 'floor-plan-editor-2d';

import { DerivativesEnum } from '@models/enumerations/derivatives-enum.model';
import { IDerivatives } from '@models/attachment.model';
import './FloorPlanViewerReadOnly.less';
import { isArrayWithValues } from '@shared/util/array-util';
import { IAssetSR as IAsset } from '../../FloorPlanEditor/interfaces/interfaces';

export interface IFloorPlanViewerProps {
  height: number;
  containerStyle?: React.CSSProperties | undefined;
  initialAssets?: IAsset[] | [];
}

export const FloorPlanViewerReadOnly = (props: IFloorPlanViewerProps) => {
  const { initialAssets = [], height, containerStyle = {} } = props;

  let floorPlanEditor = useRef<FloorPlanEditor | undefined>();
  let floorPlanContainerRef = useRef<HTMLDivElement>(null);

  const {
    token: { colorPrimaryBorderHover, colorPrimary },
  } = theme.useToken();

  const [floorPlanEditorIsReady, setfloorPlanEditorIsReady] = useState<Boolean>(false);

  const initializeEditor = () => {
    const floorPlanInitialAssets: IAsset[] = [...initialAssets];
    const isEditMode = true;
    const floorplanCallbacks: IFloorplanCallbacks = {};

    const floorplanConfig: IFloorplanConfig = {
      backgroundColor: 'transparent',
      gridPatternConfig: {
        size: 0.1,
        color: 'transparent',
      },
      defaultAssetColor: colorPrimaryBorderHover,
      backgroundImage: '',
      allowSingleSelection: true,
      allowMultipleAdd: false,
      strokeConfig: {
        width: 1.2,
      },
      dimensionsConfig: {
        pixelsPerMeter: 45,
        officeWidth: 100,
        officeHeight: 67,
      },
      markerRadiusMeters: 0.5,
      showFloorplanControls: {
        showMinimap: false,
        showDeleteButton: false,
        showResetButton: false,
        showClearButton: false,
        showZoomSlider: false,
        showMoveButtons: false,
        showRotateButtons: false,
        showDuplicateButton: false,
      },
      allowedAssetTypes: [],
    };

    if (floorPlanContainerRef?.current) {
      if (floorPlanContainerRef.current.innerHTML !== '') {
        floorPlanContainerRef.current.innerHTML = '';
      }
      floorPlanEditor.current = new FloorPlanEditor(
        floorPlanContainerRef.current,
        floorPlanInitialAssets,
        isEditMode,
        floorplanCallbacks,
        floorplanConfig
      );
    }

    setfloorPlanEditorIsReady(true);
  };

  useLayoutEffect(() => {
    initializeEditor();

    return () => {
      setfloorPlanEditorIsReady(false);
      if (floorPlanEditor) {
        floorPlanEditor?.current?.destroy?.();
      }
    };
  }, []);

  useLayoutEffect(() => {
    floorPlanEditorIsReady && floorPlanEditor?.current?.setAllAssets?.(initialAssets);
    floorPlanEditorIsReady && floorPlanEditor?.current?.setSelectedAsset(initialAssets);

    // POC Go to Point (Zoom Animation Included)
    // initialAssets[0] && floorPlanEditor.goTo(initialAssets[0].x, initialAssets[0].y, 0.5);
  }, [initialAssets]);

  useLayoutEffect(() => {
    if (isArrayWithValues(initialAssets)) {
      if (floorPlanEditorIsReady && floorPlanEditor?.current) {
        const punchListItemData = initialAssets[0].data;

        const derivativeFounded =
          punchListItemData?.punchlistPage?.floorPlan?.derivatives?.find(
            derivative => derivative.derivativeType === DerivativesEnum.PREVIEW
          ) ?? ({} as IDerivatives);

        if (derivativeFounded.signedUrl) {
          floorPlanEditor?.current?.setBackgroundImage?.(derivativeFounded.signedUrl);
        }
      }
    }
  }, [initialAssets]);

  return (
    <>
      <div className="relative">
        <div
          id="floor-plan-container-read-only"
          ref={floorPlanContainerRef}
          style={{
            width: `100%`,
            height: height,
            background: 'transparent',
            ...containerStyle,
          }}
        ></div>
        <div
          id="overlay"
          className="absolute w-full h-full top-0 left-0"
          style={{
            border: `1px solid var(--neutral-4, #F0F0F0)`,
            borderRadius: 4,
            backgroundColor: `${colorPrimary}19`,
          }}
        ></div>
      </div>
    </>
  );
};
