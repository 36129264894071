import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, InputNumber, Select, Steps, Typography, theme } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { TabContainer } from '@components/Utils/TabContainer';
import { formatDatetimeWithAmPm } from '@shared/util/date-utils';
import { createByTemplate } from '@store/slices/budget-extended';
import { getEntities as getBudgetTemplates } from '@store/slices/budget-template';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getActiveEntities as getActiveCurrencies } from '@store/slices/currency';
import { asyncLaunchNotification } from '@store/slices/notification';

const { Text } = Typography;

export const BudgetPlanningForm = ({ previous }: { previous: () => void }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: locationId } = useParams();
  const { siteId } = useParams();
  const { projectId } = useParams();

  const { entities: budgetTemplates } = useAppSelector(state => state.BudgetTemplate);
  const { activeEntities: currencies } = useAppSelector(state => state.Currency);
  const { entity: projectEntity } = useAppSelector(state => state.Project);

  const {
    token: { colorPrimary, colorBgContainer, colorText },
  } = theme.useToken();
  const { t } = useTranslation();
  const [form] = useForm();

  const [animateError, setAnimateError] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    dispatch(getActiveCurrencies());
    dispatch(getBudgetTemplates({ sort: 'name,asc', 'active.equals': true }));
  }, [dispatch]);

  const stepsItems = [{ title: 'Settings' }, { title: 'Add Cost Values' }, { title: 'Submission' }];

  const onFinish = ({ currency, area, template }) => {
    if (!projectId) return;
    setProcessing(true);
    dispatch(createByTemplate({ projectId, budgetTemplateId: template, currencyId: currency, squareFootage: Math.trunc(area) }))
      .unwrap()
      .then(() => {
        dispatch(
          asyncLaunchNotification({
            type: 'success',
            config: {
              message: t('budget.budget'),
              description: t('budget.created'),
            },
          })
        );
        navigate(`/location/${locationId}/sites/${siteId}/project/${projectId}/budget/detail`);
        setProcessing(false);
      })
      .catch((error: any) => {
        console.error(error);
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('budget.errorCreation'),
              description: error?.response?.data?.title,
            },
          })
        );
        setProcessing(false);
      });
  };

  const currencyOptions = currencies.map(currency => ({
    value: currency.id,
    label: `${currency.symbol} - ${currency.isoCode} - ${currency.name}`,
  }));

  const filterFunction = (inputValue: string, option) => option!.label!.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  return (
    <TabContainer>
      <div
        className="flex flex-col mt-16 ml-40 mb-8 p-20"
        style={{ width: '648px', backgroundColor: colorBgContainer, borderRadius: '0.25rem', gap: '0.75rem' }}
      >
        <div className="flex justify-center">
          <Text style={{ fontSize: '20px', fontWeight: 500, color: colorPrimary }}>{t('budget.planning')}</Text>
        </div>
        <div className="mb-20">
          <Steps progressDot items={stepsItems} size="small" current={-1} />
        </div>
        <div style={{ paddingLeft: '1.5rem', fontSize: '14px', color: colorText }}>
          <span style={{ fontWeight: 700 }}>{t('budget.dateOfCreation')}: </span>
          <span>{formatDatetimeWithAmPm(dayjs().toString())}</span>
        </div>
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            currency: projectEntity?.currency?.id,
            area: projectEntity?.squareFootage,
          }}
          className={`${animateError && 'error-animation'}`}
        >
          <Form.Item name="currency" label={t('budget.currency')} rules={[{ required: true }]}>
            <Select
              showSearch
              allowClear={true}
              options={currencyOptions}
              filterOption={filterFunction}
              placeholder={t('budget.selectCurrency')}
            />
          </Form.Item>
          <Form.Item name="area" label={t('budget.squareFootage')} rules={[{ required: true }]}>
            <InputNumber formatter={value => `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} style={{ width: '25%' }} min={1} />
          </Form.Item>
          <Form.Item name="template" label={t('budget.selectTemplate')} rules={[{ required: true, message: t('budget.templateRequired') }]}>
            <Select
              showSearch
              allowClear={true}
              options={budgetTemplates.map(bt => ({ label: bt.name, value: bt.id }))}
              filterOption={filterFunction}
              placeholder={t('budget.selectTemplate')}
            />
          </Form.Item>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item>
              <Button type="primary" ghost htmlType="button" onClick={previous}>
                {t('generic.cancel')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={processing} disabled={processing}>
                {t('generic.saveAndContinue')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </TabContainer>
  );
};
