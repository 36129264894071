import { WarningOutlined } from '@ant-design/icons';
import { BudgetApprovalStatus, BudgetStatusEnum } from '@models/budget.model';
import { capitalCaseToTitleCase } from '@shared/util/string-utils';
import { getLatestByProjectId, submitForApproval } from '@store/slices/budget-extended';
import { getBudgetSummary } from '@store/slices/budget-summary';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Button, Card, Modal, Typography, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetApprovalStatusBox } from './BudgetApprovalStatusBox';
import { normalizeNumberString } from '@shared/util/number-util';
import { SetSquareSvgIcon } from '@components/Icons/SetSquareSvgIcon';
import { useParams } from 'react-router-dom';
import { getBudgetApprovers } from '@store/slices/budget-approvers';
import { IUser } from '@models/user.model';
import { useAuth } from '@providers/AuthProvider';
import { isArrayWithValues } from '@shared/util/array-util';

const { Text } = Typography;

const isPendingBudgetApproval = (status: BudgetApprovalStatus): boolean => {
  return status === BudgetApprovalStatus.PENDING_APPROVAL;
};

const BudgetSummary = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    token: { colorTextTertiary, colorFillSecondary, colorFillTertiary, colorTextSecondary, colorPrimary },
  } = theme.useToken();
  const { entity: budget } = useAppSelector(state => state.BudgetExtended);
  const { entity: budgetSummary } = useAppSelector(state => state.BudgetSummary);
  const { entity: budgetApprovers } = useAppSelector(state => state.BudgetApprover);
  const { user: userLogged } = useAuth();
  const { projectId } = useParams();

  const currencySymbol = budget?.currency?.symbol ?? '$';

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const showModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleOk = () => {
    if (!budget.id) return;
    setConfirmLoading(true);
    dispatch(submitForApproval(budget.id))
      .unwrap()
      .then(() => {
        dispatch(
          asyncLaunchNotification({ type: 'success', config: { message: t('budget.budget'), description: t('budget.submit.success') } })
        );
        projectId && dispatch(getLatestByProjectId(projectId));
        dispatch(getBudgetSummary(budget.id as number));
        dispatch(getBudgetApprovers(budget.id as number));
      })
      .catch(err => {
        dispatch(
          asyncLaunchNotification({ type: 'error', config: { message: t('budget.budget'), description: t('budget.submit.error') } })
        );
      })
      .finally(() => {
        setIsConfirmModalOpen(false);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    if (!budget.id) return;
    dispatch(getBudgetSummary(budget.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  const ModalTitle = () => (
    <>
      <WarningOutlined className="mr-8" style={{ color: colorPrimary }} />
      <span>{t('budget.submit.title')}</span>
    </>
  );

  const [isCurrentUserTheApprover, setIsCurrentUserTheApprover] = useState<boolean>(false);

  useEffect(() => {
    if (!isArrayWithValues(budgetApprovers)) return;

    const isLoggedUser = (user: IUser | null | undefined) => {
      return String(user?.id) === String(userLogged?.id);
    };

    const currentApprovalIndex = budgetApprovers?.findIndex(item => item.status && isPendingBudgetApproval(item?.status));
    const approverUser = budgetApprovers![currentApprovalIndex]?.user;
    setIsCurrentUserTheApprover(isLoggedUser(approverUser));
  }, [budgetApprovers, userLogged?.id]);

  return (
    <>
      <Card
        className="mt-15 mr-15 ml-15"
        title={`${t('budget.budget')} ${t('budget.summary')}`}
        style={{ borderRadius: '0.25rem' }}
        styles={{
          header: {
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: '16px',
            fontWeight: 500,
            color: colorTextTertiary,
          },
          body: { paddingLeft: 15, paddingTop: 15, paddingBottom: 0, paddingRight: 0 },
        }}
      >
        <div className="grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)', grid: '15px' }}>
          <div
            className="flex mb-15 mr-15"
            style={{
              border: `1px solid ${colorFillSecondary}`,
              background: `${colorFillTertiary}`,
              borderRadius: '0.25rem',
              flexDirection: 'column',
            }}
          >
            <div className="m-10">
              <Text style={{ fontSize: '14px', fontWeight: 500, color: colorTextSecondary }}>{t('budget.totalEstimatedCost')}:</Text>
              <div>
                <Text style={{ fontSize: '14px', fontWeight: 400, color: colorTextTertiary }}>
                  {currencySymbol} {normalizeNumberString(budgetSummary.totalEstimatedCost.toString())}
                </Text>
              </div>
            </div>
          </div>
          <div
            className="flex mb-15 mr-15"
            style={{
              border: `1px solid ${colorFillSecondary}`,
              background: `${colorFillTertiary}`,
              borderRadius: '0.25rem',
              flexDirection: 'column',
            }}
          >
            <div className="m-10">
              <div className="flex items-center" style={{ gap: '0.5rem' }}>
                <Text style={{ fontSize: '14px', fontWeight: 500, color: colorTextSecondary }}>{t('budget.costPerSF')}</Text>
                <SetSquareSvgIcon />
              </div>
              <div>
                <div className="cost-per-sf-cell-wrap flex justify-center items-center" style={{ display: 'inline-block' }}>
                  <span className="cost-per-sf-cell-currency">{currencySymbol} </span>
                  <span>{normalizeNumberString(budgetSummary.totalCostPerSF.toString())}</span>
                </div>
              </div>
            </div>
          </div>
          {budget?.status && budget.status !== BudgetStatusEnum.DRAFT && budget.status !== BudgetStatusEnum.ARCHIVED && (
            <BudgetApprovalStatusBox status={budget.status} isApproverUser={isCurrentUserTheApprover} />
          )}
          {budget?.status && budget.status === BudgetStatusEnum.DRAFT && (
            <div
              className="flex justify-between items-center mb-15 mr-15 p-12"
              style={{
                gridColumn: '1/-1',
                border: `1px solid ${colorFillSecondary}`,
                background: `${colorFillTertiary}`,
                borderRadius: '0.25rem',
              }}
            >
              <div>
                <Text style={{ fontSize: '14px', fontWeight: 500, color: colorTextSecondary }}>
                  {t('audit.punchListItem.STATUS')}:{' '}
                  <Text style={{ fontSize: '14px', fontWeight: 400, color: colorTextTertiary }}>
                    {budget.status ? capitalCaseToTitleCase(budget.status) : ''}
                  </Text>
                </Text>
              </div>
              <div>
                <Button type="primary" onClick={showModal}>
                  {t('budget.submit.cta')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
      <Modal
        title={<ModalTitle />}
        open={isConfirmModalOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText={t('budget.submit.cta')}
        centered
      >
        <p style={{ color: colorTextSecondary }}>{t('budget.submit.description')}</p>
      </Modal>
    </>
  );
};

export default BudgetSummary;
