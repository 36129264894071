import { TabContainer } from '@components/Utils/TabContainer';
import { Col, Row, theme } from 'antd';
import { BudgetDetailTable } from './BudgetDetailTable';
import ProjectInfo from './ProjectInfo';
import BudgetSummary from './BudgetSummary';
import BudgetApprovers from './BudgetApprovers';
import CreateRevision from './CreateRevision';
import BudgetAlertLatestVersion from './BudgetAlertLatestVersion';
import { useLayoutEffect, useRef, useState } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';

export const BudgetDetailTab = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { layoutSize, headerSize } = useLayoutContex();
  const tabContainerReference = useRef<HTMLDivElement>(null);
  const [heightTab, setheightTab] = useState(1);

  useLayoutEffect(() => {
    setTimeout(() => {
      const topPosition = (tabContainerReference?.current && tabContainerReference?.current?.getBoundingClientRect().top) || 0;
      const headerSizeLayout = typeof headerSize === 'undefined' ? 0 : headerSize;
      const tabBarHeightRelative = topPosition > 0 ? topPosition - headerSizeLayout : headerSizeLayout;
      if (typeof layoutSize.layoutHeight !== 'undefined') {
        setheightTab(layoutSize?.layoutHeight - tabBarHeightRelative);
      }
    }, 0);
  }, [layoutSize.layoutHeight, headerSize]);

  const heightCalculated = { height: `${heightTab}px` };
  return (
    <div ref={tabContainerReference}>
      <TabContainer style={heightCalculated}>
        <Row className="overflow-auto" style={{ ...heightCalculated }}>
          <Col span={17} className="overflow-auto" style={{ ...heightCalculated }}>
            <div className=" mt-16 ml-20 mb-8 p-20 " style={{ backgroundColor: colorBgContainer, borderRadius: '0.25rem', gap: '0.75rem' }}>
              <BudgetDetailTable />
            </div>
          </Col>
          <Col span={7} className="overflow-auto" style={{ ...heightCalculated }}>
            <div className=" flex flex-col ml-20 " style={{ backgroundColor: colorBgContainer }}>
              <BudgetAlertLatestVersion />
              <ProjectInfo />
              <BudgetSummary />
              <BudgetApprovers />
              <CreateRevision />
            </div>
          </Col>
        </Row>
      </TabContainer>
    </div>
  );
};
